export const navigationLinks = [
    {
        name:'HOME',
        ref:'/'
    },
    {
        name:'ABOUT ME',
        ref:'#about-me'
    },  {
        name:'PROJECTS',
        ref:'#projects'
    },  {
        name:'RESUME',
        ref:'#resume'
    }, 
    {
        name:'CONTACT',
        ref:'#contact'
    },
    // {
    //     name:'',
    //     ref:'/home'
    // },
]